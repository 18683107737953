import { defaultConfig } from "./constants";
import { WIDGET_EVENT } from "../src/enums/widget_event";
import { IConfig } from "./interfaces";
import "./styles/index.scss";
import { addAnimation, EventBus, loadScript } from "./utils";
const MATIC_WIDGET = "matic-widget";

declare var polygonWidgetManifest;

export class Widget {

    private _config: IConfig;
    private _eventBus = new EventBus();
    private _el!: HTMLElement;
    private isVisible = false;
    private _onMessageFromWidget!: Function;
    private loaderElem = document.createElement('div');
    private assetsLoaded = false;


    constructor(config: IConfig) {
        if (!config.network) {
            throw `{Matic Widget Error} : network is not specified. Please specify network type.`;
        }
        this._config = { ...defaultConfig, ...config };
    }

    assetsLoader() {
        const config = this._config;

        this.loaderElem.className = `matic-widget matic-widget__loading`;
        if (!this.isMobileDevice()) {
            this.loaderElem.style.cssText = `height: ${config.height}px; width: ${config.width}px;`
            this.loaderElem.classList.add(`matic-widget--${config.position}`);
        } else {
            this.loaderElem.classList.add('mobile-position');
        }

        const loadingStyle = config.loading as any;
        for (const key in loadingStyle) {
            this.loaderElem.style[key] = loadingStyle[key];
        }
        
        const loaderMessage = document.createElement('div');
        loaderMessage.innerHTML += '...loading';
        this.loaderElem.appendChild(loaderMessage);
        document.body.appendChild(this.loaderElem);
    }

    async loadAssets(srcList: string[]) {
        const promises: Promise<any>[] = srcList.map(src => {
            return loadScript(src);
        })
        return Promise.all(promises);
    }

    async getAssets() {
        const manifestPath = Widget.assetsUrl + "manifest.js";
        await loadScript(manifestPath);
        return polygonWidgetManifest;
    }

    async create() {
        const config = this._config;

        if (config.assetsFromWidgetServer) {
            const assets = await this.getAssets();
            const srcList: any[] = assets.map(asset => {
                return `${Widget.assetsUrl}${asset}`;
            })
            await this.loadAssets(srcList);
        }

        if (config.target) {
            const els: HTMLElement[] = document.querySelectorAll(config.target) as any;
            if (els.length == 0) {
                throw `{Matic Widget Error} : invalid target supplied. Please provide a valid element selector.`;
            }
            els.forEach((el) => {
                el.onclick = () => {
                    this.show();
                }
            })
        }

        const el = document.createElement('div');
        this._el = el;
        el.className = `matic-widget matic-widget--hide`;
        if (!this.isMobileDevice()) {
            el.style.cssText = `height:${config.height}px;width:${config.width}px;`;
            el.classList.add(`matic-widget--${config.position}`);
        } else {
            el.classList.add('mobile-position');
        }
        const configStyle = config.style;
        for (const key in configStyle) {
            el.style[key] = configStyle[key];
        }

        if (config.overlay) {
            el.className += ` matic-widget__overlay`;
        }

        const widgetEl = document.createElement(MATIC_WIDGET);
        widgetEl.className = "matic-widget__iframe";
        widgetEl.setAttribute('appid', config.appName)
        widgetEl.setAttribute('page', config.page as any);
        widgetEl.setAttribute('amount', config.amount as any);
        widgetEl.setAttribute('network', config.network as any);
        if (this.isMobileDevice()) {
            widgetEl.setAttribute('closable', 'true');    
        } else {
            widgetEl.setAttribute('closable', config.closable as any);
        }

        el.appendChild(widgetEl);

        document.body.appendChild(el);
        this._onMessageFromWidget = (ev: any) => {
            const detail = ev.detail;
            if (!detail) return;
            this._eventBus.emit(detail.name, detail.payload);
            switch (detail.name) {
                case WIDGET_EVENT.close:
                    this.hide(); break;
            }
        };
        widgetEl.addEventListener('message', this._onMessageFromWidget as any);

        const autoShowTime = Number(config.autoShowTime);
        if (!isNaN(autoShowTime) && autoShowTime>0) {
            setTimeout(() => {
                this.show();
            }, autoShowTime);
        }
        this.assetsLoaded = true;

    }

    show() {
        if (!this.assetsLoaded) {
            this.assetsLoader();
        }
        if (this.isVisible) return;
        this._el.classList.add('matic-widget--slide-down');
        this._el.classList.remove('matic-widget--hide');
        setTimeout(() => {
            addAnimation(this._el, 'matic-widget--slide-up').then(_ => {
                this._el.classList.remove('matic-widget--slide-down');
                this._eventBus.emit('show');
                const loaderElem = this.loaderElem;
                if (loaderElem && document.body.contains(loaderElem)) {
                    document.body.removeChild(loaderElem);
                    this.loaderElem=null as any;
                }
            })
        }, 100);
        this.isVisible = true;
    }

    hide() {
        if (!this.isVisible) return;
        addAnimation(this._el, 'matic-widget--slide-down').then(_ => {
            this._eventBus.emit('hide');
            this._el.classList.add('matic-widget--hide');
            this.isVisible = false;
        })
    }

    on(event: string, callback: Function) {
        this._eventBus.on(event, callback);
        return this;
    }

    off(event: string, callback: Function) {
        this._eventBus.off(event, callback);
    }

    isMobileDevice() {
        return window.screen.width < 576
    }

    destroy() {
        const el = this._el;
        if (!el) return;
        const widget = el.querySelector('matic-widget');
        widget?.removeEventListener('message', this._onMessageFromWidget as any);
        document.body.removeChild(el);
    }

    static assetsUrl = `https://wallet-asset.matic.network/widget/`
}

